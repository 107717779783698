import { gql } from '@apollo/client';

export const REGISTER_COURRIER = gql`
  mutation ($userName: String, $userEmail: String, $userPassword: String) {
    registerUser(userName: $userName, userEmail: $userEmail, userPassword: $userPassword)
  }
`;
export const LOGIN_COURRIER = gql`
  query ($userEmail: String, $userPassword: String) {
    loginUser(userEmail: $userEmail, userPassword: $userPassword) {
      status
      token
      message
      user {
        _id
        userName
        userEmail
        isEmailVerified
        avatar
        courrier {
          isActive
          _id
          logo
          phoneNumber
          isPhoneVerified
          isCompleted
          companyName
          officeAddress
          officeCity
          deliveryFees {
            sizeCategory {
              _id
              name
            }
            insideAreaFees
            remoteAreaFees
          }
          officeMapLocation {
            coordinates
          }
          tradeLicence
          isDeleted
          status
        }
      }
    }
  }
`;
export const VERIFY_TOKEN = gql`
  {
    verifyToken {
      _id
      userName
      userEmail
      isEmailVerified
      courrier {
        bankAccount {
          accountNumber
          iban
          accountHolderName
          bankName
          city
        }
        isActive
        transactions {
          _id
          adminId {
            userId {
              userName
            }
          }
          amount
          receipt
          isDeleted
          updatedAt
        }
        _id
        logo
        phoneNumber
        isPhoneVerified
        isCompleted
        companyName
        officeAddress
        pendingAmount
        releasedAmount
        deliveryFees {
          sizeCategory {
            _id
            name
          }
          insideAreaFees
          remoteAreaFees
          status
          returnMessage
          updatedAt
        }
        officeCity
        tradeLicence
        officeMapLocation {
          coordinates
        }
        isDeleted
        status
        validationHistory {
          status
          createdAt
        }
      }
    }
  }
`;
export const UPDATE_ACC_SETTING = gql`
  query ($userEmail: String, $oldPassword: String, $newPassword: String) {
    updateAccountSetting(
      userEmail: $userEmail
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      status
      message
    }
  }
`;
export const SET_COURIER_STATUS = gql`
  mutation ($isActive: Boolean) {
    setCourierActiveOrUnActive(isActive: $isActive) {
      status
      message
    }
  }
`;
export const UPDATE_BANK_ACCOUNT_DETAILS = gql`
  mutation (
    $accountNumber: String
    $iban: String
    $city: String
    $bankName: String
    $accountHolderName: String
  ) {
    updateBankAccountDetails(
      accountNumber: $accountNumber
      iban: $iban
      city: $city
      bankName: $bankName
      accountHolderName: $accountHolderName
    ) {
      status
      message
    }
  }
`;
export const ADD_BANK_ACCOUNT_DETAILS = gql`
  mutation (
    $accountNumber: String
    $iban: String
    $city: String
    $bankName: String
    $accountHolderName: String
  ) {
    addBankAccountDetails(
      accountNumber: $accountNumber
      iban: $iban
      city: $city
      bankName: $bankName
      accountHolderName: $accountHolderName
    ) {
      status
      message
    }
  }
`;
